<template>
  <b-container>
    <b-row>
      <b-col> API IP address: {{ ip }} </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Ip',
  created: async function () {
    try {
      let response = await this.$http.get('https://api.ipify.org/')
      this.ip = response.data
      console.log(this.ip)
    } catch (e) {
      console.log(e)
    }
  },
  data () {
    return {
      ip: '... loading ...'
    }
  },
  methods: {}
}
</script>

<style></style>
